<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form ref="form">
      <v-container>
        <v-row class="mt-3 mb-0">
          <v-col cols="12" md="8" class="py-0 my-0">
            <h5>Hotel Features</h5>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0">
            <v-card class="px-3 py-2 default" outlined>What features are available for the hotel?</v-card>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col
            cols="12"
            md="8"
            v-for="(feature, index) in form.hotel_features"
            :key="index"
            class="pr-3 my-0 feature-height"
          >
            <label
              :class="{
                'text--secondary': !form.hotel_features[index].selected
              }"
              ><i :class="form.hotel_features[index].icon + ' mr-3'"></i> {{ form.hotel_features[index].name }}</label
            >
            <v-checkbox
              class="float-right mt-0"
              v-model="form.hotel_features[index].selected"
              :key="index"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="12" md="8">
            <v-text-field
              label="Other feature name"
              v-model="newFeature"
              append-icon="mdi-plus"
              :rules="[getRules.string]"
              @keydown.enter.prevent="addFeature"
              @click:append="addFeature"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-3 mb-0">
          <v-col cols="12" md="8" class="py-0 my-0 d-flex">
            <h5 class=""><v-icon class="mdi mdi-room-service"></v-icon> Food & Beverage</h5>
            <v-checkbox class="mt-0 ml-auto" v-model="form.is_food_and_beverage_available"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0">
            <v-card class="px-3 py-2 default" outlined
              >What food & beverage services are available in the hotel?</v-card
            >
          </v-col>
        </v-row>
        <!--Restaurants (START)-->
        <v-row class="mt-3 mb-0" v-if="form.is_food_and_beverage_available">
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-card class="px-3 py-2 bg-color-grey" outlined>
              <v-row class="py-0 my-0">
                <v-col cols="12" md="12" class="pt-4 pb-0 mb-0 d-flex">
                  <div>
                    Restaurants
                    <v-chip v-if="form.restaurants.length > 0" color="default">{{ form.restaurants.length }}</v-chip>
                  </div>
                  <v-checkbox class="py-0 mt-0 ml-auto" v-model="form.is_restaurant_available"></v-checkbox>
                </v-col>
                <v-col cols="12" md="12" class="py-0 my-0" v-if="form.is_restaurant_available">
                  <v-card class="px-3 pb-3 mb-3" v-if="form.restaurants.length > 0">
                    <v-row class="py-0 my-0">
                      <v-col
                        cols="12"
                        md="12"
                        class="py-0 my-0"
                        v-for="(restaurant, restaurantIndex) in form.restaurants"
                        :key="restaurantIndex"
                      >
                        <!--Text (START)-->
                        <v-row
                          class="py-0 my-0"
                          :class="{
                            'custom-editable':
                              form.restaurants.length !== 1 && form.restaurants.length > restaurantIndex + 1
                          }"
                          v-if="!restaurant.editable"
                        >
                          <v-col cols="12" md="9">
                            <div>{{ restaurant.name }}</div>
                            <div>
                              {{ restaurant.cuisines.toString() }} - {{ restaurant.price_range }} -
                              {{ restaurant.seating_capacity }}
                            </div>
                          </v-col>
                          <v-col cols="12" md="3" class="pt-4 my-0 text-right">
                            <v-btn x-small @click="editRestaurant(restaurantIndex)" fab
                              ><v-icon class="mdi mdi-pencil"></v-icon
                            ></v-btn>
                            <v-btn x-small @click="removeRestaurant(restaurantIndex)" fab class="ml-1"
                              ><v-icon class="mdi mdi-close"></v-icon
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <!--Text (END)-->

                        <!--Edit (START)-->
                        <v-row
                          class="pt-3 pb-0 mt-3 mb-0"
                          :class="{
                            'custom-editable': form.restaurants.length > restaurantIndex + 1
                          }"
                          v-if="restaurant.editable"
                        >
                          <v-col cols="12" md="9" class="py-0 my-0">
                            <v-row class="py-0 my-0">
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-text-field
                                  label="Restaurant Name *"
                                  v-model="restaurant.name"
                                  :rules="[getRules.required]"
                                  class="py-0 my-0"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-select
                                  multiple
                                  :items="cuisines"
                                  label="Cuisines *"
                                  v-model="restaurant.cuisines"
                                  :rules="[getRules.required]"
                                  class="py-0 my-0"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row class="py-0 my-0">
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-select
                                  :items="['€', '€€', '€€€', '€€€€']"
                                  label="Price Range"
                                  v-model="restaurant.price_range"
                                  class="py-0 my-0"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-text-field
                                  min="0"
                                  :rules="[
                                    getRules.required,
                                    getRules.nonNegativeQuantity,
                                    getRules.number,
                                    getRules.isInteger
                                  ]"
                                  label="Seating Capacity *"
                                  v-model="restaurant.seating_capacity"
                                  class="py-0 my-0"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="3" class="pt-8 my-0 text-right">
                            <v-btn x-small @click="saveRestaurant(restaurantIndex)" fab
                              ><v-icon class="mdi mdi-content-save"></v-icon
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <!--Edit (END)-->
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card class="px-3 pb-3" v-if="toggleAddRestaurant">
                    <v-row class="py-0 my-0">
                      <v-col cols="12" md="12" class="py-0 my-0">
                        <v-text-field
                          v-model="newRestaurant.name"
                          label="Restaurant Name *"
                          :rules="[getRules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-0 my-0">
                        <v-select
                          class="py-0 my-0 mt-2"
                          label="Cuisine *"
                          multiple
                          clearable
                          :items="cuisines"
                          v-model="newRestaurant.cuisines"
                          :rules="[getRules.required]"
                          chips
                          deletable-chips
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-2 pb-0 my-0">
                        <label class="mr-2 text--secondary"><small>Price Range</small></label>
                        <v-btn-toggle mandatory v-model="newRestaurant.price_range">
                          <v-btn small value="€">€</v-btn>
                          <v-btn small value="€€">€€</v-btn>
                          <v-btn small value="€€€">€€€</v-btn>
                          <v-btn small value="€€€€">€€€€</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0 my-0">
                        <v-text-field
                          label="Seating Capacity *"
                          :rules="[getRules.required, getRules.nonNegativeQuantity, getRules.number]"
                          class="py-0 my-0"
                          v-model="newRestaurant.seating_capacity"
                          min="0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-0 my-0 mt-1 text-right">
                        <v-btn label="Cancel" class="mr-2 primary" small @click="toggleAddRestaurant = false"
                          >Cancel</v-btn
                        >
                        <v-btn label="Add Restaurant" class="primary" small @click="addRestaurant"
                          >Add Restaurant</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card>

                  <div @click="toggleAddRestaurant = true" class="mt-3 pointer text--secondary">
                    <v-icon small class="mdi mdi-plus"></v-icon> Add a Restaurant
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0"></v-col>
        </v-row>
        <!--Restaurants (END)-->

        <!--Bars (START)-->
        <v-row class="mt-3 mb-0" v-if="form.is_food_and_beverage_available">
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-card class="px-3 py-2 bg-color-grey" outlined>
              <v-row class="py-0 my-0">
                <v-col cols="12" md="12" class="pt-4 pb-0 mb-0 d-flex">
                  <div>
                    Bars
                    <v-chip v-if="form.bars.length > 0" color="default">{{ form.bars.length }}</v-chip>
                  </div>
                  <v-checkbox class="py-0 mt-0 ml-auto" v-model="form.is_bar_available"></v-checkbox>
                </v-col>
                <v-col cols="12" md="12" class="py-0 my-0" v-if="form.is_bar_available">
                  <v-card class="px-3 pb-3 mb-3" v-if="form.bars.length > 0">
                    <v-row class="py-0 my-0">
                      <v-col cols="12" md="12" class="py-0 my-0" v-for="(bar, barIndex) in form.bars" :key="barIndex">
                        <!--Text (START)-->
                        <v-row
                          class="py-0 my-0"
                          :class="{
                            'custom-editable': form.bars.length !== 1 && form.bars.length > barIndex + 1
                          }"
                          v-if="!bar.editable"
                        >
                          <v-col cols="12" md="9">
                            <div>{{ bar.name }}</div>
                            <div>
                              {{ bar.bar_type }} - {{ bar.price_range }} -
                              {{ bar.seating_capacity }}
                            </div>
                          </v-col>
                          <v-col cols="12" md="3" class="pt-4 my-0 text-right">
                            <v-btn x-small @click="editBar(barIndex)" fab
                              ><v-icon class="mdi mdi-pencil"></v-icon
                            ></v-btn>
                            <v-btn x-small @click="removeBar(barIndex)" fab class="ml-1"
                              ><v-icon class="mdi mdi-close"></v-icon
                            ></v-btn>
                          </v-col>
                        </v-row>
                        <!--Text (END)-->

                        <!--Edit (START)-->
                        <v-row
                          class="pt-3 pb-0 mt-3 mb-0"
                          :class="{
                            'custom-editable': form.bars.length > barIndex + 1
                          }"
                          v-if="bar.editable"
                        >
                          <v-col cols="12" md="9" class="py-0 my-0">
                            <v-row class="py-0 my-0">
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-text-field
                                  label="Bar Name *"
                                  v-model="bar.name"
                                  :rules="[getRules.required]"
                                  class="py-0 my-0"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-select
                                  :items="barTypes"
                                  label="Bar Type *"
                                  v-model="bar.bar_type"
                                  :rules="[getRules.required]"
                                  class="py-0 my-0"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row class="py-0 my-0">
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-select
                                  :items="['€', '€€', '€€€', '€€€€']"
                                  label="Price Range"
                                  v-model="bar.price_range"
                                  class="py-0 my-0"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0 my-0">
                                <v-text-field
                                  min="0"
                                  label="Seating Capacity *"
                                  :rules="[
                                    getRules.required,
                                    getRules.nonNegativeQuantity,
                                    getRules.number,
                                    getRules.isInteger
                                  ]"
                                  v-model="bar.seating_capacity"
                                  class="py-0 my-0"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="3" class="pt-8 my-0 text-right">
                            <v-btn x-small @click="saveBar(barIndex)" fab
                              ><v-icon class="mdi mdi-content-save"></v-icon
                            ></v-btn>
                            <!--<v-btn x-small @click="closeBar(barIndex)" fab class="ml-1"><v-icon class="mdi mdi-close"></v-icon></v-btn>-->
                          </v-col>
                        </v-row>
                        <!--Edit (END)-->
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card class="px-3 pb-3" v-if="toggleAddBar">
                    <v-row class="py-0 my-0">
                      <v-col cols="12" md="12" class="py-0 my-0">
                        <v-text-field
                          v-model="newBar.name"
                          label="Bar Name *"
                          :rules="[getRules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-0 my-0">
                        <v-select
                          class="py-0 my-0 mt-2"
                          label="Bar Type *"
                          :items="barTypes"
                          v-model="newBar.bar_type"
                          :rules="[getRules.required]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-2 pb-0 my-0">
                        <label class="mr-2 text--secondary"><small>Price Range</small></label>
                        <v-btn-toggle mandatory v-model="newBar.price_range">
                          <v-btn small value="€">€</v-btn>
                          <v-btn small value="€€">€€</v-btn>
                          <v-btn small value="€€€">€€€</v-btn>
                          <v-btn small value="€€€€">€€€€</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="12" md="6" class="py-0 my-0">
                        <v-text-field
                          label="Seating Capacity *"
                          :rules="[
                            getRules.required,
                            getRules.nonNegativeQuantity,
                            getRules.number,
                            getRules.isInteger
                          ]"
                          class="py-0 my-0"
                          min="0"
                          v-model="newBar.seating_capacity"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="py-0 my-0 mt-1 text-right">
                        <v-btn label="Cancel" class="mr-2 primary" small @click="toggleAddBar = false">Cancel</v-btn>
                        <v-btn label="Add Bar" class="primary" small @click="addBar">Add Bar</v-btn>
                      </v-col>
                    </v-row>
                  </v-card>

                  <div @click="toggleAddBar = true" class="mt-3 pointer text--secondary">
                    <v-icon small class="mdi mdi-plus"></v-icon> Add a Bar
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0">
            <!--<v-card class="px-3 py-2 default" outlined>What features are available for the hotel?</v-card>-->
          </v-col>
        </v-row>
        <!--Bars (END)-->
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Amenities",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    newFeature: "",
    toggleAddRestaurant: false,
    toggleAddBar: false,
    cuisines: [
      "French",
      "Chinese",
      "Japanese",
      "Italian",
      "Greek",
      "Spanish",
      "Mediterranean",
      "Turkish",
      "Thai",
      "Indian",
      "Mexican",
      "Carribean",
      "German",
      "Russian",
      "American",
      "European",
      "Fusion"
    ],

    barTypes: [
      "Rooftop",
      "Beach",
      "Pool",
      "Panorama",
      "Underground",
      "Urban",
      "Hotel",
      "Terrace",
      "Garden",
      "Private",
      "Karaoke",
      "Music",
      "Sports",
      "Beer",
      "Hall",
      "Wine",
      "Cocktail"
    ],
    newRestaurant: {
      name: null,
      cuisines: null,
      price_range: null,
      seating_capacity: null,
      editable: false
    },
    newBar: {
      name: null,
      bar_type: null,
      price_range: null,
      seating_capacity: null,
      editable: false
    }
  }),
  computed: {
    ...mapGetters(["getRules", "getAuthErrors", "getVendor", "getIsVendorUpdate"])
  },
  methods: {
    editRestaurant(restaurantIndex) {
      this.form.restaurants[restaurantIndex].editable = true;
    },
    saveRestaurant(restaurantIndex) {
      this.closeRestaurant(restaurantIndex);
    },
    closeRestaurant(restaurantIndex) {
      this.form.restaurants[restaurantIndex].editable = false;
    },
    removeRestaurant(restaurantIndex) {
      this.form.restaurants = _.reject(this.form.restaurants, (restaurant, index) => {
        return restaurantIndex === index;
      });
    },
    addRestaurant() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.form.restaurants.push({
        name: this.newRestaurant.name,
        cuisines: this.newRestaurant.cuisines,
        price_range: this.newRestaurant.price_range,
        seating_capacity: this.newRestaurant.seating_capacity,
        editable: false
      });
      this.resetRestaurant();
    },
    resetRestaurant() {
      this.newRestaurant.name = null;
      this.newRestaurant.cuisines = null;
      this.newRestaurant.price_range = null;
      this.newRestaurant.seating_capacity = null;
      this.toggleAddRestaurant = false;
    },
    editBar(barIndex) {
      this.form.bars[barIndex].editable = true;
    },
    saveBar(barIndex) {
      this.closeBar(barIndex);
    },
    closeBar(barIndex) {
      this.form.bars[barIndex].editable = false;
    },
    removeBar(barIndex) {
      this.form.bars = _.reject(this.form.bars, (bar, index) => {
        return barIndex === index;
      });
    },
    addBar() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.form.bars.push({
        name: this.newBar.name,
        bar_type: this.newBar.bar_type,
        price_range: this.newBar.price_range,
        seating_capacity: this.newBar.seating_capacity,
        editable: false
      });
      this.resetBar();
    },
    resetBar() {
      this.newBar.name = null;
      this.newBar.bar_type = null;
      this.newBar.price_range = null;
      this.newBar.seating_capacity = null;
      this.toggleAddBar = false;
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("hotelNextStep", this.index);
    },
    addFeature() {
      if (this.$refs.form.validate()) {
        if (this.newFeature.replace(/ /g, "") !== "") {
          this.form.hotel_features.push({
            name: this.newFeature,
            icon: "mdi mdi-auto-fix",
            selected: false,
            removable: true
          });
          this.newFeature = "";
        }
      }
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}
.feature-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
.custom-editable {
  border-bottom: 1px solid #b3b3b3;
}
</style>
